import React from 'react';
import Header from "../header";
import Footer from "../../components/common/landFreightForwardingFooter";
import LandFright from '../../components/common/land-freight';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext ,Link} from "gatsby-plugin-react-i18next";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
 const Landfreight = () => {
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/land-freight-software/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/product/land-freight-software/`
      const canoncalUrl = buildCanonicalUrl();

      const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();

      const { t } = useTranslation()
    return (
        <div className="Layout">
            
            <Header/>
            <LandFright/>
            <Footer/>
            <Helmet>
            <meta charSet="utf-8" />
          <title>{t('Land Freight Solutions - Road Freight Forwarding Software')}</title>
          <Link rel="canonical" href={canoncalUrl} />  
          <Link rel="alternate" href={hrefUrl} hreflang={language} />
          <meta name="description" content={t("Avail Logipulse Land Freight Solutions to manage the complete road shipping. Our Land Freight Forwarding Software allows you to create quotes, manage on-road shipments, and be up-to-date on the warehouse space and inventory in real-time.")} />
          <meta name="Keywords" content={t("land freight solutions, land freight forwarding software, road freight software")}/> 
            </Helmet>
        </div>
    )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Landfreight