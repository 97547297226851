import React, { Component } from "react"
import Photo from "../Photo"
import "../../styles/air-freight.scss";
import "../../styles/threeplwarehousing.scss";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

class landfright extends Component {
  static contextType = I18nextContext;
  constructor() {
    super()
    this.state = {
      showMe: true,
      test2: String,
      test1: String,
      Multilingual: true,
      landoperation: true,
    }
    const test2 = `
    <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
`
    const test1 = `
 <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

 `
  }
  Schedule(e, curObj) {

    var status = false;
    if (document.getElementById('feature__' + curObj).classList.contains('active_color')) {
      document.getElementById('feature__' + curObj).classList.remove('active_color');
      document.getElementById('arrow__' + curObj).classList.remove('active_color');
      document.getElementById('feature__' + curObj).classList.add('inactive_color');
      document.getElementById('arrow__' + curObj).classList.add('inactive_color');
      status = false;
    } else {
      document.getElementById('feature__' + curObj).classList.add('active_color');
      document.getElementById('arrow__' + curObj).classList.add('active_color');
      document.getElementById('feature__' + curObj).classList.remove('inactive_color');
      document.getElementById('arrow__' + curObj).classList.remove('inactive_color');
      status = true;
    }

    var custObj = {};
    custObj[curObj] = status;
    this.setState(custObj);
  }


  landoperation() {
    document.getElementById("land").style.backgroundColor = "#fff"
    document.getElementById("air").style.backgroundColor = "#f0f7ff"
    document.getElementById("ocean").style.backgroundColor = "#f0f7ff"
    this.setState({ landoperation: true })
  }


  render() {
    return (
      <div>
        <Helmet>
          {this.state.test2}
          {this.state.test1}
        </Helmet>
        <div className="mbl_block_productpage">
          <div className="banner-freight">
            <div className="div-contain mbl_block_productpage" lang={I18nextContext._currentValue.language}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">

                  </div>
                </div>
              </div>
            </div>

            <div className="banner-feature">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="content-imgs">
                      <div className="pricings_in_details main-details" lang={I18nextContext._currentValue.language}>
                        <Link to="/"><Trans>HOME</Trans></Link>/ <span className="Land_fright_content"><Link to="/product/"><Trans>PRODUCTS</Trans></Link> / </span>
                        <span className="Land_fright_subcontent"><Trans>LAND FREIGHT SOLUTIONS</Trans></span>
                      </div>
                      <div className="sub_main_contents" lang={I18nextContext._currentValue.language}>
                        <Trans>We help you keep track of your packages</Trans>{" "}
                      </div>
                      <div className="threewarehouse_third_content" lang={I18nextContext._currentValue.language}>
                        <Trans>Integrated and automated to move on roads and connect every other means of freight forwarding from end to end.</Trans>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {this.state.landoperation ? (
            <div className="air-freights" lang={I18nextContext._currentValue.language}>
              <div className="container">
                <h1 className="Land_label" lang={I18nextContext._currentValue.language}><Trans>Land Freight Solutions</Trans></h1>

                <div className="row  landspacing">
                  <div className="col-md-5">
                    <Photo
                      src="freight-sub-img-truck.png"
                      className="brandsimages1"
                      alt=""
                    />
                  </div>

                  <div className="col-md-7 pl-md-0">
                    <div className="sample_contents" lang={I18nextContext._currentValue.language}>
                      <Trans>Manage a complete freight process on-road or be a part of air or ocean freight forwarding to conclude it favourably.</Trans>
                      <br />


                    </div>

                    <div className="sub_main_heads" lang={I18nextContext._currentValue.language}>
                      <Trans>The most accessible freight forwarding service has to be the most advanced to better serve the people, thereby making it the most profitable to you.</Trans>

                      <br />
                      <br />
                      <Trans>Door-to-door pick-up to delivery is no more a complicated task. Logipulse land freight forwarding module allows you to create quotes, manage courier shipments, and be up to date on the warehouse space and inventory in real-time. Manual tasks, paperwork, weight calculation and more, are less time-consuming and tedious when replaced with the automated operations of the app. Also, you could easily convert your shipment into a domestic/export activity or a pickup/delivery order.</Trans>


                      <br></br>



                    </div>
                  </div>
                </div>

                <div className="highlights" lang={I18nextContext._currentValue.language}>
                  <div className="container">


                    <div className="hight_light_heads" lang={I18nextContext._currentValue.language}><Trans>Highlights</Trans></div>

                    <div className="row arabicformat" lang={I18nextContext._currentValue.language}>
                      <div className="col-md-6 full_row_align">
                        <ul>
                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} id="arrow__Multilingual" onClick={e => this.Schedule(e, 'Multilingual')}>
                              {this.state.Multilingual ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            {/* <CheckCircleOutlineOutlinedIcon className="materials_iconstesting"/> */}
                            <h5
                              className="active_color"
                              id="feature__Multilingual"
                              onClick={e => this.Schedule(e, 'Multilingual')}
                            >
                              <Trans>Multi Language Interface</Trans>
                            </h5>
                            {this.state.Multilingual ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>
                                  As the freight forwarding process involves many locations in single freight itself, it is necessary for you to have a global user interface with multiple languages. English is the globally accepted language and most of the time it is the language that connects people internationally, but your native local language is also equally important when it comes to the logistics business. You can switch between any language with a click. The entire application and data will switch to your desired language instantly. Logipulse is enabled with multiple languages to break the barrier of boundaries.</Trans>

                                <div className="know_more">
                                  <b><Link to="/features/#multilingual" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__multicurrency" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'multicurrency')}>
                              {this.state.multicurrency ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__multicurrency"
                              onClick={e => this.Schedule(e, 'multicurrency')}
                            >
                              <Trans>Multicurrency</Trans>
                            </h5>
                            {this.state.multicurrency ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>Spread out your wings without the worry of multicurrency transactions.</Trans>
                                <Trans>With Logipulse, you can easily handle 3 levels of currency as well as review the financial statements in any currency selected.</Trans>
                                <Trans>For example, let's say your branch is in UAE. At the time of generating the invoice, you can select the local currency (eg. AED) or a currency which the customer wants (eg. USD) and the payment can be done in the currency of the invoice (either AED or USD as selected) or you can opt for a third new currency (eg. Euro).</Trans>

                                <div className="know_more">
                                  <b><Link to="/features/#multicurrency" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__quotes" onClick={e => this.Schedule(e, 'quotes')} style={{ cursor: "pointer" }}>
                              {this.state.quotes ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__quotes"
                              onClick={e => this.Schedule(e, 'quotes')}
                            >
                              <Trans>Quotes</Trans>
                            </h5>
                            {this.state.quotes ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>
                                  Logipulse quotation management module lets you create and send professional digital quotes to your customers instantly. Display a quotation form for walk-in inquiries and include all contract rates from carriers. You can convert a CRM sales lead into a quote by arranging a follow-up discussion with the customer. You can supervise all your existing and pending quotes approval all under one platform with transparency.</Trans><br></br><br></br>

                                <div className="know_more">
                                  <b><Link to="/features/#Quotes" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__job" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'job')}>
                              {this.state.job ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__job"
                              onClick={e => this.Schedule(e, 'job')}
                            >
                              <Trans>Jobs</Trans>
                            </h5>
                            {this.state.job ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>With this module, you can create and schedule jobs quickly. Simply choose shipments accordingly with a click. You get a job module synchronized with the finance module and 3-PL module. You can schedule or reschedule the jobs in 3 modes of transportation. A custom house can be created. Also, have the advantage of getting all the related documents instantly, house waybill, bill of lading, air waybill, truck waybill, multi-currency details, packing item details, types of transportation, etc. You can keep internal notes, manifest reports, other records, and job info while creating or scheduling the job. You can also deliver or convert shipments of jobs to the warehouse.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#Jobs" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__transportations" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'transportations')}>
                              {this.state.transportations ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__transportations"
                              onClick={e => this.Schedule(e, 'transportations')}
                            >
                              <Trans>Transportation</Trans>
                            </h5>
                            {this.state.transportations ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>Logipulse transportation gives visibility into your everyday transportation operations, compliance information, and documentation, and maintains a well-timed delivery of freight and goods. We help you streamline the shipping process and make it easier for you to manage and optimize all your transportation operations, whether they are land, air, or sea.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#Transportation" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__financemodule" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'financemodule')}>
                              {this.state.financemodule ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__financemodule"
                              onClick={e => this.Schedule(e, 'financemodule')}
                            >
                              <Trans>Integrated Finance Module</Trans>
                            </h5>
                            {this.state.financemodule ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>One of the unique features of Logipulse is its fully functional integrated finance module with the operations module.</Trans> 
                                <Trans>Whenever we post charges in the operations module, it will automatically reflect in the finance module by posting accounting entries in the background.</Trans>
                                <Trans>The same transactions can be seen by using our wide range of reports from ledger to balance sheet including sundry debtors and creditors, actor specific ledger, general ledger, cash flow statements, trial balance, profit & loss and balance sheet.</Trans> 
                                <Trans>These reports are available both at Branch and headquarter level.</Trans>
                                <Trans>To know the financial status of the customers and vendors we have reports such as aging report, outstanding reports, statement of accounts and payment summary.</Trans> 
                                <Trans>While maintaining a specific ledger for every walk-in customer or vendor, the chart of accounts will get bulky or enormous. So instead, Logipulse manages all the actor accounts through the concept of virtual ledger.</Trans>


                                <div className="know_more">
                                  <b><Link to="/features/#IntegratedFinance" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__sharingdata" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'sharingdata')}>
                              {this.state.sharingdata ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__sharingdata"
                              onClick={e => this.Schedule(e, 'sharingdata')}
                            >
                              <Trans>Data Sharing with Agencies and Branches</Trans>
                            </h5>
                            {this.state.sharingdata ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>With Logipulse you are getting a centralized data storage that is accessible to you, your agencies, and the branches in real-time without a local server or backup facility, or security system. Every hassle of these kinds of technical aspects, for instance, network, firewall, connectivity, backup, etc. will be managed by Logipulse for you. Even modified information can be shared on this platform. You don't have to worry about files getting lost or sending every document physically with your associates.</Trans>


                                <div className="know_more">
                                  <b><Link to="/features/#DataSharing" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__shipment" onClick={e => this.Schedule(e, 'shipment')} style={{ cursor: "pointer" }}>
                              {this.state.shipment ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__shipment"
                              onClick={e => this.Schedule(e, 'shipment')}
                            >
                              <Trans>Direct Shipments and Consolidation (LTL and FTL)</Trans>
                            </h5>
                            {this.state.shipment ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>

                                <Trans>Logipulse provides you the facility to optimize direct shipments (FTL) and consolidation (LTL) within a short duration, by utilizing the space effectively and cost-efficiently with the technology of advanced algorithms. You get the advantage to generate pre-alerts easily. Pre-alerts include house waybill, master waybill, manifest documents, etc.</Trans>

                                <div className="know_more">
                                  <b><Link to="/features/#DirectShipments" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} id="arrow__predictions" onClick={e => this.Schedule(e, 'predictions')}>
                              {this.state.predictions ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__predictions"
                              onClick={e => this.Schedule(e, 'predictions')}
                            >
                              <Trans>Automated Rate Prediction</Trans>
                            </h5>
                            {this.state.predictions ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>
                                  Logipulse can help you gather the rates so that you can pass your charges instantly including your margin also. You can speed up your operations as well as customer relations with the assistance of Logipulse automated rate prediction.
                                </Trans>

                              </div>
                            ) : null}
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-6">
                        <ul>
                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} id="arrow__alloments_charge" onClick={e => this.Schedule(e, 'alloments_charge')}>
                              {this.state.alloments_charge ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__alloments_charge"
                              onClick={e => this.Schedule(e, 'alloments_charge')}
                            >
                              <Trans>Charge Allotment</Trans>
                            </h5>
                            {this.state.alloments_charge ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>When you split a job into multiple shipments or a shipment into multiple jobs, you get the charges of a shipment divided into different jobs or vice-versa automatically. This will be done accurately according to the predefined conditions. With the charge allotment feature, you will get the idea of whether your shipment is a profit or a loss.</Trans>

                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'stateupdates')} id="arrow__stateupdates">
                              {this.state.stateupdates ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__stateupdates"
                              onClick={e => this.Schedule(e, 'stateupdates')}
                            >
                              <Trans>Integrated State Updates</Trans>
                            </h5>
                            {this.state.stateupdates ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>Logipulse provides you with a dedicated and most intelligent state engine that can track all the entities in your logistics business.</Trans>
                                <Trans>It gives you separate updates at shipment, job, and item, etc.</Trans> 
                                <Trans>Whenever a state is changed for an item or any operation, an alert will be generated to you, your employees, consignee, or any other required individual.</Trans> 
                                <Trans>You will know whether a task assigned is started, partially completed, or completed.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#IntegratedState" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'automateddocument')} id="arrow__automateddocument">
                              {this.state.automateddocument ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__automateddocument"
                              onClick={e => this.Schedule(e, 'automateddocument')}
                            >
                              <Trans>Automated Documents Generation</Trans>
                            </h5>
                            {this.state.automateddocument ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>Different versions of documents can be generated in Logipulse. Documents can be shared between agencies and can be emailed to desired people. Editable templates are available for you to create documents instantly. There are numerous documents required from pickup to delivery, automating and customizing it according to your need will make the process professional, accurate and fast.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#AutomatedDocuments" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" id="arrow__TaskApprovals" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'TaskApprovals')}>
                              {this.state.TaskApprovals ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__TaskApprovals"
                              onClick={e => this.Schedule(e, 'TaskApprovals')}
                            >
                              <Trans>Task Approvals and Permissions</Trans>
                            </h5>
                            {this.state.TaskApprovals ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>Nothing goes without the knowledge and approval of the authorized person you assigned. Every small modification made in the business or organization will be notified for approvals and permissions. The subsequent action will take effect only after it. Logipulse can handle approvals, permissions, and scalate messages in case a person misses a notification throughout the operations.</Trans>


                                <div className="know_more">
                                  <b><Link to="/features/#Taskapproval" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} id="arrow__portals" onClick={e => this.Schedule(e, 'portals')}>
                              {this.state.portals ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__portals"
                              onClick={e => this.Schedule(e, 'portals')}
                            >
                              <Trans>Customer Portal & Tracking Using Waybill or Shipment number</Trans>
                            </h5>
                            {this.state.portals ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>You can provide your customers access to track their shipments using waybill or shipment numbers through the customer portal. Using access details your customers can log in to the portal and see the details of the shipments, for instance, the item details, quantity, etc.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#CustomerPortal" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" onClick={e => this.Schedule(e, 'Climb')} style={{ cursor: "pointer" }} id="arrow__Climb">
                              {this.state.Climb ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__Climb"
                              onClick={e => this.Schedule(e, 'Climb')}
                            >
                              <Trans>Events Notifications and Escalation-Messages</Trans>
                            </h5>
                            {this.state.Climb ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>All logistics events are tracked in Logipulse. All event notifications can be configured according to your requirement. You can decide when and to whom you want to notify. Events and notifications will appear in that person's inbox. In case the message is ignored or overlooked by the person the notification will climb up to the higher employee.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#Alertengine" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'customizablereports')} id="arrow__customizablereports">
                              {this.state.customizablereports ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__customizablereports"
                              onClick={e => this.Schedule(e, 'customizablereports')}
                            >
                              <Trans>Customizable Reports</Trans>
                            </h5>
                            {this.state.customizablereports ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>With our customisable report engine, one does not need to rely on the technical support to customise your reports to your service providers as anyone can do this with minimal training. This feature is also available in multiple languages making it cost friendly as well.</Trans>
                                {/* <div className="know_more">
                                  <b><Trans>KNOW MORE</Trans></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div> */}
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} id="arrow__Warehousing" onClick={e => this.Schedule(e, 'Warehousing')} >
                              {this.state.Warehousing ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__Warehousing"
                              onClick={e => this.Schedule(e, 'Warehousing')}
                            >
                              <Trans>Integrated Warehousing Solution</Trans>
                            </h5>
                            {this.state.Warehousing ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>You can maintain a number of warehouses configured in the system and when a shipment or pick up is done warehouses can be selected and allocated for the shipment items. The operation module and warehouse module are closely integrated.</Trans>
                                {/* <div className="know_more">
                                  <b><Trans>KNOW MORE</Trans></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div> */}
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" style={{ cursor: "pointer" }} onClick={e => this.Schedule(e, 'itemtracking')} id="arrow__itemtracking">
                              {this.state.itemtracking ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__itemtracking"
                              onClick={e => this.Schedule(e, 'itemtracking')}
                            >
                              <Trans>Barcode and Item tracking</Trans>
                            </h5>
                            {this.state.itemtracking ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>
                                <Trans>You can scan each item and unique barcodes will be generated, this makes the rest of the activities easier and trackable. When a barcode is scanned in every phase the state update of the item will be refreshed. Track and trace of items and shipments will be easier for you with the barcode generation feature of Logipulse.</Trans>
                                <div className="know_more">
                                  <b><Link to="/features/#QRcodes" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link></b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                          <li>
                            <div className="materials_icon" onClick={e => this.Schedule(e, 'Well')} style={{ cursor: "pointer" }} id="arrow__Well">
                              {this.state.Well ? (
                                <Photo
                                  src="arrow_circle_up_black_24dp.svg"
                                  alt=""
                                />
                              ) : (
                                <Photo
                                  src="arrow_circle_down_black_24dp (1).svg"
                                  alt=""
                                />
                              )}
                            </div>
                            <h5
                              id="feature__Well"
                              onClick={e => this.Schedule(e, 'Well')}
                            >
                              <Trans>Well Defined CRM Module</Trans>
                            </h5>
                            {this.state.Well ? (
                              <div className="subheadings_content" lang={I18nextContext._currentValue.language}>

                                <Trans>
                                  You can build up your customer circle using the facilities provided in the Logipulse CRM module. It will be easier for you to follow up enquiries and quotes. You can study how you achieved a new customer and a lot more from this section. Thus, you will be able to elaborate your customer list.
                                </Trans>
                                <div className="know_more">
                                  <b>
                                    <Link to="/features/#CRM" target="_blank" lang={I18nextContext._currentValue.language}><Trans>KNOW MORE</Trans></Link>
                                  </b>
                                  <Photo
                                    src="KnowMore-Right Arrow-H12.svg"
                                    class="testing-arrow"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </li>

                        </ul>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

          ) : null}
        </div>

        <div class="end_divis_product">
          <div className="header_contents_sections text-right"></div>
          <div className="container">
            <div className="row">
              <div class="col-sm-12">
                <Photo
                  src="Bottom Image-11.svg"
                  class="innerimage"
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>
      </div>

    )
  }
}
export default withTranslation()(landfright)
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
